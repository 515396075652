import React from 'react'
import { AlertTriangle, Plus, Trash } from 'react-feather'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

export const SaleSpecial = ({ setProductosCompra, ProductosCompra }) => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      // toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });


  // onClick={() => handledAddProduct(item.ID)}

  // const solo_num = (e) => {
  //   if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
  //   else e.preventDefault();
  // }

  // const solo_num = (e) => {
  //   const allowedKeys = [
  //     'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
  //   ];
  //   if (
  //     (e.key >= '0' && e.key <= '9') || // Números
  //     allowedKeys.includes(e.key) // Otras teclas permitidas
  //   ) {
  //     return true;
  //   } else {
  //     e.preventDefault();
  //   }
  // };


  const solo_num = (e) => {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
    ];

    const inputValue = e.target.value;
    const hasDecimal = inputValue.includes('.');

    // Si ya existe un punto decimal, solo permitir dos dígitos después de él
    if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
      e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
      return;
    }

    if (
      (e.key >= '0' && e.key <= '9') || // Números
      allowedKeys.includes(e.key) // Otras teclas permitidas
    ) {
      return true;
    } else {
      e.preventDefault(); // Evitar cualquier otra tecla
    }
  };

  // Usar en el input
  // <input type="text" onKeyDown={solo_num} />


  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      nombre: "",
      descripcion: "",
      monto: "",
      // descripcionAnticipo: ""
    }
  });

  const addArticulo = (data) => {

    console.log(data);

    Toast.fire({
      icon: 'success',
      title: 'Producto Agregado a la lista'
    });

    let sol = {
      "ID": Date.now(),
      "NOMBRE": data.nombre,
      "DESCRIPCION": data.descripcion,
      "COSTO_PUBLICO": data.monto,
      "EXISTENCIA": Date.now(),
      "CANTIDAD": 1,
      "ACUMULADO": 1 * Number(data.monto),
      "VENTA_ESPECIAL": 1,

    }
    setProductosCompra([...ProductosCompra, sol]);


    // console.log(data);
    // dispatch(startRegistroAnticipo(usuario, folio, data));
    // closeModal.current.clic();
    // closeModal.current.click();
  }

  const clearForm = () => {
    reset();
  }

  const showDivSpecialSale = (e)=>{

    console.log(e);

    let div = document.getElementById("saleSpecial_div");

    if (div && div.style.display === 'block') {
      document.getElementById("saleSpecial_div").style.display = "none";
    }else{
      document.getElementById("saleSpecial_div").style.display = "block";
    }
    

  }

  return (
    <div className='mt-2'>
      <form className='container row' onSubmit={handleSubmit(addArticulo)}>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3">
          <label className="form-label">Producto:</label>
          <input type="text" className="form-control" aria-describedby="emailHelp" autoComplete='off' {...register("nombre", { required: 'Nombre del articulo' })} />
          {
            errors.nombre &&
            <div className='text-left-5 mt-2'>
              <AlertTriangle size="18" color="#dc3545" />
              <span className='text-left-10 fw-bold text-danger'><small>{errors.nombre.message}</small></span>
            </div>
          }
        </div>

        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 mb-3">
          <label className="form-label">Descripcion:</label>
          <input type="text" className="form-control" aria-describedby="emailHelp"{...register("descripcion")} />
        </div>

        <div className="col-8 col-sm-8 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-3">
          <label className="form-label">Costo:</label>
          <input type="number" className="form-control" aria-describedby="emailHelp" min={1} autoComplete='off' onKeyDown={solo_num} {...register("monto", { required: 'Costo del articulo' })} />
          {
            errors.monto &&
            <div className='text-left-5 mt-2'>
              <AlertTriangle size="18" color="#dc3545" />
              <span className='text-left-10 fw-bold text-danger'><small>{errors.monto.message}</small></span>
            </div>
          }
        </div>
        <div className="col-4 col-sm-4 col-md-1 col-lg-1 col-xl-1 col-xxl-1 mb-3 mt-4">
          <div className="btn-group" role="group" aria-label="Basic example">

            <button type="button" className="btn btn-outline-danger mt-1" onClick={clearForm} ><Trash size='20' /></button>
            <button type="submit" className="btn btn-outline-dark mt-1"  ><Plus size='20' /></button>
          </div>
        </div>
        {/* <div className='col-12'>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="1" onClick={e => showDivSpecialSale(e)} id="flexCheckDefault" />
            <label className="form-check-label" >
              Compra por pedido
            </label>
          </div>
        </div> */}

        <div className='col-12' id='saleSpecial_div'  style={{ display: "none" }}>
          <div className='row mt-1'>

            <div className='col-12 col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
              <div className="mb-2">
                <label className="form-label">Enlace:</label>
                <input type='text' className="form-control" rows="2" 
                // {...register("enlace", { required: 'Enlace, para compra de refaccion' })}
                />
                {/* {
                  errors.enlace &&
                  <div className='text-left-5 mt-2'>
                    <AlertTriangle size="18" color="#dc3545" />
                    <span className='text-left-10 fw-bold text-danger'><small>{errors.enlace.message}</small></span>
                  </div>
                } */}
              </div>
            </div>

            <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <div className="mb-2">
                <label className="form-label">Anticipo:</label>
                <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} min={0}
                // {...register("costoCliente", { required: 'Costo refaccion, es requerido' })}
                />
                {/* {
                  errors.costoCliente &&
                  <div className='text-left-5 mt-2'>
                    <AlertTriangle size="18" color="#dc3545" />
                    <span className='text-left-10 fw-bold text-danger'><small>{errors.costoCliente.message}</small></span>
                  </div>
                } */}
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <div className="mb-2">
                <label className="form-label">Resta:</label>
                <input type="number" className="form-control" aria-label="First name" autoComplete='off' disabled onKeyDown={solo_num} min={0}
                // {...register("costoCliente", { required: 'Costo refaccion, es requerido' })}
                />
                {/* {
                  errors.costoCliente &&
                  <div className='text-left-5 mt-2'>
                    <AlertTriangle size="18" color="#dc3545" />
                    <span className='text-left-10 fw-bold text-danger'><small>{errors.costoCliente.message}</small></span>
                  </div>
                } */}
              </div>
            </div>
          </div>



        </div>







      </form >
    </div >
  )
}
